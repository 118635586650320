<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :visible.sync="visible"
    :title="$t('title.Update') + $t('order.Trackingnumber')"
    :before-close="handleDialogClose"
    width="40%"
  >
    <el-descriptions :column="1" style="margin-top: 24px;">
      <el-descriptions-item
        v-if="row"
        :label="$t('title.History') + $t('page.LogisticsChannel')"
      >
        {{ row.returnCarrier || '-' }}
      </el-descriptions-item>
      <el-descriptions-item
        v-if="row && row.trackingId"
        :label="$t('title.History') + $t('order.Trackingnumber')"
      >
        {{ row.trackingId }}
      </el-descriptions-item>
    </el-descriptions>
    <el-divider />
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-position="left"
      label-width="auto"
      style="padding-top: 10px"
    >
      <el-form-item prop="returnCarrier" :label="$t('title.Update') + $t('page.LogisticsChannel') + '：'">
        <el-input v-model="form.returnCarrier" clearable />
      </el-form-item>
      <el-form-item prop="trackingId" :label="$t('title.Update') + $t('order.Trackingnumber') + '：'">
        <el-input v-model="form.trackingId" clearable />
      </el-form-item>
    </el-form>
    <div style="width:100%;text-align:center;margin-top:30px;color: red">请确认无误后再点击提交按钮</div>
    <template v-slot:footer>
      <el-button size="small" @click="handleDialogClose">取消</el-button>
      <el-button size="small" type="primary" :loading="loading" @click="handleSubmit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { updateTrackingNumber } from '@/api/omsorder'

export default {
  name: 'UpdateTrace',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      form: {
        trackingId: '',
        returnCarrier: ''
      },
      rules: {
        trackingId: [{ required: true, message: this.$t('topic.trackingnumber'), trigger: 'blur' }],
        returnCarrier: [{
          required: true,
          message: this.$t('page.inputPlaceholder') + this.$t('page.LogisticsChannel'),
          trigger: 'blur'
        }]
      }
    }
  },
  computed: {
    params() {
      return {
        oldTrackingId: this.row.trackingId,
        orderCode: this.row.orderCode,
        returnCarrier: this.form.returnCarrier,
        returnId: this.row.returnId,
        sku: this.row.sku,
        trackingId: this.form.trackingId
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs['form'].validate(async(valid) => {
        if (valid) {
          if (!Object.entries(this.params).every(item => {
            if (!item[1]) {
              this.$message.warning(`该条信息缺少${item[0]}，无法更新`)
              return false
            }
            return item
          })) {
            return
          }
          this.loading = true
          const { code, msg } = await updateTrackingNumber(this.params).finally(() => {
            this.loading = false
          })
          if (code === 0) {
            this.$emit('refresh')
            this.$message.success(msg || '更新成功')
            this.handleDialogClose()
          }
        }
      })
    },
    handleDialogClose() {
      this.form = {
        trackingId: '',
        returnCarrier: ''
      }
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped>

</style>
