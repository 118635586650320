var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"top":"5vh","width":"80%","title":_vm.$t('topic.BulkRefund'),"visible":_vm.visible,"close-on-click-modal":false,"close-on-press-escape":false,"before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.visible=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.handleClose}},[_vm._v("取 消")]),_c('el-button',{attrs:{"size":"small","type":"primary","loading":_vm.submitLoading},on:{"click":_vm.handleSubmit}},[_vm._v("确 定")])]},proxy:true}])},[_c('vxe-table',{attrs:{"size":"small","align":"center","data":_vm.tableData,"max-height":580,"show-overflow":false,"scroll-y":{ enabled: false },"scroll-x":{ enabled: false },"column-config":{ resizable: true }}},[_c('vxe-column',{attrs:{"field":"orderCode","title":_vm.$t('topic.PlatformOrderNumber'),"min-width":"120"}}),(_vm.isAmazon || _vm.isStandWebsite)?_c('vxe-column',{attrs:{"field":"creatTime","title":_vm.$t('page.createTime'),"width":"140"}}):_vm._e(),_c('vxe-column',{attrs:{"field":"sku","title":"SKU","min-width":"215"}}),_c('vxe-column',{attrs:{"field":"returnNumber","title":_vm.$t('topic.Numberofrefunds')}}),_c('vxe-column',{attrs:{"field":"returnReason","title":_vm.$t('topic.reasonforreturn'),"min-width":"85"}}),_c('vxe-column',{attrs:{"field":"itemPrice","title":_vm.$t('title.orderamount') + '-' + _vm.$t('title.Orderamount'),"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" $"+_vm._s(row.itemPrice)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"itemTax","title":_vm.$t('title.orderamount') + '-' + _vm.$t('topic.Taxes'),"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" $"+_vm._s(row.itemTax)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"refundAmount","title":_vm.$t('topic.Refundamount'),"min-width":"115"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticStyle:{"color":"#f56c6c"}},[_vm._v("*")]),_c('i',{staticClass:"el-icon-edit-outline"}),_c('span',[_vm._v(_vm._s(_vm.$t('topic.Refundamount')))])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticClass:"number-unit"},[_vm._v(" $ ")]),_c('el-input-number',{staticClass:"number-input",attrs:{"size":"small","min":0,"controls-position":"right","step":0.01},model:{value:(row.refundAmount),callback:function ($$v) {_vm.$set(row, "refundAmount", $$v)},expression:"row.refundAmount"}})],1)]}}])}),(_vm.isAmazon)?_c('vxe-column',{attrs:{"field":"otherRefund","title":_vm.$t('topic.Additionalrefunds')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" $"+_vm._s(row.otherRefund)+" ")]}}],null,false,2155517905)}):_vm._e(),(_vm.isStandWebsite)?_c('vxe-column',{attrs:{"field":"shippingPrice","title":_vm.$t('topic.Shippingfeerefund')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" $"+_vm._s(row.shippingPrice)+" ")]}}],null,false,1193880676)}):_vm._e(),_c('vxe-column',{attrs:{"title":_vm.$t('page.operate'),"width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var rowIndex = ref.rowIndex;
return [_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.handleRemove(rowIndex)}}},[_vm._v(_vm._s(_vm.$t('title.Remove')))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }