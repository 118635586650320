<template>
  <el-dialog
    top="5vh"
    width="80%"
    :title="$t('topic.BulkRefund')"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleClose"
  >
    <vxe-table
      size="small"
      align="center"
      :data="tableData"
      :max-height="580"
      :show-overflow="false"
      :scroll-y="{ enabled: false }"
      :scroll-x="{ enabled: false }"
      :column-config="{ resizable: true }"
    >
      <vxe-column field="orderCode" :title="$t('topic.PlatformOrderNumber')" min-width="120" />
      <vxe-column v-if="isAmazon || isStandWebsite" field="creatTime" :title="$t('page.createTime')" width="140" />
      <vxe-column field="sku" title="SKU" min-width="215" />
      <vxe-column field="returnNumber" :title="$t('topic.Numberofrefunds')" />
      <vxe-column field="returnReason" :title="$t('topic.reasonforreturn')" min-width="85" />
      <vxe-column field="itemPrice" :title="$t('title.orderamount') + '-' + $t('title.Orderamount')" width="80">
        <template #default="{ row }">
          ${{ row.itemPrice }}
        </template>
      </vxe-column>
      <vxe-column field="itemTax" :title="$t('title.orderamount') + '-' + $t('topic.Taxes')" width="80">
        <template #default="{ row }">
          ${{ row.itemTax }}
        </template>
      </vxe-column>
      <vxe-column field="refundAmount" :title="$t('topic.Refundamount')" min-width="115">
        <template #header>
          <span style="color: #f56c6c">*</span>
          <i class="el-icon-edit-outline" />
          <span>{{ $t('topic.Refundamount') }}</span>
        </template>
        <template #default="{ row }">
          <div style="display: flex;justify-content: center">
            <div class="number-unit">
              $
            </div>
            <el-input-number v-model="row.refundAmount" class="number-input" size="small" :min="0" controls-position="right" :step="0.01" />
          </div>
        </template>
      </vxe-column>
      <vxe-column v-if="isAmazon" field="otherRefund" :title="$t('topic.Additionalrefunds')">
        <template #default="{ row }">
          ${{ row.otherRefund }}
        </template>
      </vxe-column>
      <vxe-column v-if="isStandWebsite" field="shippingPrice" :title="$t('topic.Shippingfeerefund')">
        <template #default="{ row }">
          ${{ row.shippingPrice }}
        </template>
      </vxe-column>
      <vxe-column :title="$t('page.operate')" width="60">
        <template #default="{ rowIndex }">
          <el-button size="small" type="text" @click="handleRemove(rowIndex)">{{ $t('title.Remove') }}</el-button>
        </template>
      </vxe-column>
    </vxe-table>
    <template #footer>
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button size="small" type="primary" :loading="submitLoading" @click="handleSubmit">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { batchRefund, getReturnsInfosByIds } from '@/api/omsorder'

export default {
  name: 'BulkRefundDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    platform: {
      type: String,
      default: 'amazon'
    },
    platformId: {
      type: Number,
      default: null
    },
    refundList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      submitLoading: false,
      tableData: []
    }
  },
  computed: {
    idList() {
      return this.refundList.map(item => item.id)
    },
    queryParams() {
      return {
        refundIdList: this.idList,
        platform: this.platformId
      }
    },
    isAmazon() {
      return this.platform === 'amazon'
    },
    isEbay() {
      return this.platform === 'ebay'
    },
    isWalmart() {
      return this.platform === 'walmart'
    },
    isStandWebsite() {
      return this.platform === 'standWebsite'
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.getRefundList()
      }
    }
  },
  methods: {
    async getRefundList() {
      this.submitLoading = true
      const { datas = [] } = await getReturnsInfosByIds(this.queryParams).finally(() => {
        this.submitLoading = false
      })
      this.tableData = datas.map(item => {
        return {
          ...item,
          sku: item?.sku?.split(',').join(';\n')
        }
      })
    },
    handleClose() {
      this.tableData = []
      this.$emit('update:visible', false)
    },
    handleRemove(index) {
      this.tableData.splice(index, 1)
    },
    async handleSubmit() {
      if (!this.tableData.length) {
        this.$message.warning('请添加退款信息')
        return
      }
      if (this.tableData.some(item => item.refundAmount === null || item.refundAmount === undefined)) {
        this.$message.warning('退款金额不能为空')
        return
      }
      this.submitLoading = true
      const { msg } = await batchRefund(this.tableData).finally(() => {
        this.submitLoading = false
      })
      this.$message.success(msg)
      this.$emit('refresh')
      this.handleClose()
    }
  }
}
</script>

<style lang="scss" scoped>
.number-input {
  ::v-deep .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.number-unit {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f7fa;
  width: 30px;
  border: 1px solid #dcdfe6;
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
</style>
